<template>
    <main-displays v-bind="{ code, limit }" v-slot="{ products }">
        <v-sheet cols="12" class="d-none d-lg-block" v-if="0 < products.length">
            <div class="tit-wrap tit-wrap--xs">
                <div class="tit tit--xxs font-weight-medium">다른 고객이 같이 구매한 상품</div>
            </div>
            <v-divider dark style="border-width: 0 0 2px" class="gutter" />
            <v-row class="row--xxs">
                <v-col cols="12">
                    <v-row class="product-items">
                        <template v-for="(item, index) in products">
                            <shop-product-item :key="index" :value="item" sm="6" md="3" lg="3" />
                        </template>
                    </v-row>
                </v-col>
                <v-col v-if="1 < page">
                    <v-row justify="center" class="row--xxs">
                        <v-col cols="auto">
                            <v-btn :disabled="page === 1" icon class="v-size--xx-small" @click="go('prev')">
                                <v-icon class="font-size-24 white--text">mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn :disabled="page === pageCount" icon class="v-size--xx-small" @click="go('next')">
                                <v-icon class="font-size-24 white--text">mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>
    </main-displays>
</template>

<script>
import { DISPLAY_CODES } from "@/assets/variables";

import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import MainDisplays from "@/components/client/main/main-displays.vue";

export default {
    components: {
        ShopProductItem,
        MainDisplays,
    },
    data: () => ({
        code: DISPLAY_CODES.RECOMMENDATION.value,

        products: [],
        summary: { totalCount: 0 },
        limit: 20,
        page: 1,
    }),
    computed: {
        items() {
            return this.products;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    methods: {
        go() {},
    },
};
</script>

<style lang="scss" scoped>
.gutter {
    margin-bottom: var(--grid-gutter);
}
</style>