<template>
    <product-item v-bind="{ product, isSoldOut }" hideDivider isLink>
        <template #select>
            <v-simple-checkbox v-bind="{ ...checkbox_primary }" :value="isSelected" class="d-inline-block" @click.prevent.stop.capture="toggle" />
        </template>
        <template #content>
            <v-row class="row--sm" no-gutters>
                <template v-for="(item, index) in carts">
                    <v-col :key="`item-${index}`" cols="12" :class="{ 'mt-n4px': index != 0 }">
                        <v-card tile :color="$vuetify.breakpoint.lgAndDown ? 'grey lighten-5' : 'transparent'">
                            <div class="pa-10px pa-xl-0">
                                <v-row class="row--xs">
                                    <v-col>
                                        <template v-if="item.isShippingOutdated">
                                            <v-tooltip bottom color="error" close-delay="100" max-width="200px">
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-icon small color="error" class="mr-1" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
                                                </template>
                                                <v-card-subtitle class="py-1 px-0">[만료배송정책]</v-card-subtitle>
                                                <v-card-text class="px-0 pb-1"> 배송정책이 변경되었습니다. 주문수정 버튼을 눌러 배송정책을 변경해주세요. </v-card-text>
                                            </v-tooltip>
                                        </template>
                                        <span class="txt txt--xs">{{ formatCart(item) }}</span>
                                    </v-col>
                                    <v-col v-if="item?._supply || item?._related || (carts?.length || 0) - (carts__supply?.length || 0) - (carts__related?.length || 0) - 1" cols="auto">
                                        <v-icon dense color="grey lighten-3" @click="$emit('remove', item)">mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </template>
                <v-col>
                    <cart-form :value="carts" v-bind="{ _product: product?._id }" @search="$emit('search')">
                        <template #activator="{ attrs, on }">
                            <div class="text-right text-xl-left">
                                <v-btn v-bind="{ ...btn_tertiary, ...attrs, disabled: isSoldOut }" v-on="on" class="v-size--xx-small w-100 w-md-auto">주문수정</v-btn>
                            </div>
                        </template>
                    </cart-form>
                </v-col>
            </v-row>
        </template>
    </product-item>
</template>

<script>
import { btn_tertiary, checkbox_primary } from "@/assets/variables/attrs";

import CartForm from "@/components/client/shop/cart/cart-form.vue";
import ProductItem from "@/components/client/mypage/product/product-item.vue";

export default {
    components: {
        CartForm,
        ProductItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        carts: { type: Array, default: () => [] },
        product: { type: Object, default: () => ({}) },
    },
    data: () => ({
        btn_tertiary,
        checkbox_primary,
    }),
    computed: {
        isSoldOut() {
            return !this.product?.sells;
        },
        isSelected() {
            const _selected = this.value.map(({ _id }) => _id);
            return !this.carts.some(({ _id }) => !_selected.includes(_id));
        },
        carts__supply() {
            return this.carts.filter(({ _supply }) => _supply);
        },
        carts__related() {
            return this.carts.filter(({ _related }) => _related);
        },
    },
    methods: {
        toggle() {
            const _selected = this.value.map(({ _id }) => _id);
            const pushAbles = this.carts.filter(({ _id }) => !_selected.includes(_id));

            if (pushAbles.length) this.$emit("input", [...this.value, ...pushAbles]);
            else {
                const _carts = this.carts.map(({ _id }) => _id);
                const selected = this.value.filter(({ _id }) => !_carts.includes(_id));
                this.$emit("input", selected);
            }
        },
        formatCart({ name, amount, price }) {
            let text = "";

            if (name) text += `${this.$decode__productOptionName(name)} `;
            if (amount) text += `/ ${amount.format()}개 `;
            if (name != "본품") {
                if (0 < price) text += `(+${(price * amount).format()}원)`;
                if (price < 0) text += `(${(price * amount).format()}원)`;
            }
            return text.trim();
        },
    },
};
</script>

<style></style>
