var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-18px px-md-40px py-md-32px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('div', {
    staticClass: "tit font-weight-light"
  }, [_vm._v("주문정보")])]), _c('v-row', {
    staticClass: "row--sm txt font-weight-light",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("총 주문금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.productPrice.format()) + "원")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt font-weight-light"
  }, [_vm._v("+ 총 배송비")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm.servicePrice + _vm.deliveryPrice).format()) + "원")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt font-weight-light"
  }, [_vm._v("- 총 할인금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.discountPrice.format()) + "원")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("총 " + _vm._s(_vm.selected.length) + " 개")])]), _c('v-col', {
    staticClass: "text-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit font-weight-light"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _vm._v("원 ")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "grey lighten-5 pa-18px px-md-40px py-md-24px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_vm._v("이용안내")])]), _c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("상품은 30일간 보관됩니다. 장기간 보관을 원하실 경우 [관심상품]을 이용해 주세요.")]), _c('p', [_vm._v("주문 시 편의점 픽업을 지정하신 경우, 편의점 픽업가능 상품끼리만 주문 가능하며 한 곳의 편의점으로만 배송이 가능합니다.")]), _c('p', [_vm._v("실 결제금액은 주문결제에서 프로모션/쿠폰 적용에 따라 달라질 수 있습니다")])])]), _c('v-btn', _vm._b({
    attrs: {
      "tile": "",
      "block": "",
      "color": "v-size--xx-large primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('order', _vm.selected);
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("구매하기")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }