var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "d-xl-none py-16px"
  }, [_c('div', [_c('v-row', {
    staticClass: "txt txt--xs txt--dark font-weight-medium",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 상품금액 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.totalPrice.format()) + "원")])])], 1)], 1), _c('div', {
    staticClass: "mt-8px"
  }, [_c('v-row', {
    staticClass: "txt txt--xs",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.shippingMethodName) + " ")]), _vm.shippingChargeType != _vm.SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value ? [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s((_vm.servicePrice + _vm.deliveryPrice).format()) + "원")])])] : _vm._e()], 2)], 1)]), _c('div', {
    staticClass: "d-none d-xl-flex align-center h-100 pa-12px py-md-16px"
  }, [_c('v-row', {
    staticClass: "row--xxs text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("상품금액")]), _c('strong', {
    staticClass: "font-size-16 break-all"
  }, [_vm._v(_vm._s(_vm.totalPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular"
  }, [_vm._v("원")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s(_vm.shippingMethodName) + " "), _vm.shippingChargeType != _vm.SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value ? [_c('br'), _vm._v(" " + _vm._s((_vm.servicePrice + _vm.deliveryPrice).format()) + "원 ")] : _vm._e()], 2)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }