<template>
    <v-sheet color="transparent">
        <!-- S: Mobile -->
        <div class="d-xl-none py-16px">
            <div>
                <v-row no-gutters justify="space-between" class="txt txt--xs txt--dark font-weight-medium">
                    <v-col cols="auto"> 상품금액 </v-col>
                    <v-col cols="auto">
                        <strong>{{ totalPrice.format() }}원</strong>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-8px">
                <v-row no-gutters justify="space-between" class="txt txt--xs">
                    <v-col cols="auto"> {{ shippingMethodName }} </v-col>
                    <template v-if="shippingChargeType != SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value">
                        <v-col cols="auto">
                            <span class="font-weight-medium">{{ (servicePrice + deliveryPrice).format() }}원</span>
                        </v-col>
                    </template>
                </v-row>
            </div>
        </div>
        <!-- E: Mobile -->

        <!-- S: PC -->
        <div class="d-none d-xl-flex align-center h-100 pa-12px py-md-16px">
            <v-row class="row--xxs text-center">
                <v-col cols="12">
                    <div class="txt txt--xs">상품금액</div>
                    <strong class="font-size-16 break-all">{{ totalPrice.format() }}<small class="font-size-14 font-weight-regular">원</small></strong>
                </v-col>
                <v-col cols="12">
                    <div class="txt txt--xs">
                        {{ shippingMethodName }}
                        <template v-if="shippingChargeType != SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value">
                            <br />
                            {{ (servicePrice + deliveryPrice).format() }}원
                        </template>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- E: PC -->
    </v-sheet>
</template>

<script>
import { SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables/constants";
import { initShopShippingMethod } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Array, default: () => [] }, // selected
        carts: { type: Array, default: () => [] },
        shippingMethod: { type: Object, default: initShopShippingMethod },
        shippingChargeType: { type: String, default: null },
    },
    data: () => ({
        SHIPPING_OPTION_CHARGE_TYPES,
    }),
    computed: {
        totalPrice() {
            return this.carts.reduce((total, item) => total + item.salePrice * item.amount, 0);
        },
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts);
        },
        shippingMethodName() {
            return this.shippingMethod?.name || this.shippingMethod?.code;
        },
    },
};
</script>

<style></style>
