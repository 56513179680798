<template>
    <tbody>
        <template v-for="(shippingGroup, i) in sellerGroup.shippingGroups">
            <template v-for="(productGroup, j) in shippingGroup.productGroups">
                <body-item
                    v-model="selected"
                    v-bind="{
                        seller: i == 0 && j == 0 ? sellerGroup.seller : null,
                        shippingGroup: j == 0 ? shippingGroup : null,
                        productGroup,

                        col3Props: j == 0 ? col3Props : null,
                        col4Props: { rowspan: i == 0 ? shippingGroup.productGroups.length : null },
                    }"
                    v-on="{
                        search,
                        remove,
                        input: emit,
                    }"
                    :key="productGroup?._product"
                />
            </template>
        </template>
    </tbody>
</template>

<script>
import BodyItem from "./body/body-item.vue";

export default {
    components: {
        BodyItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        sellerGroup: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        selected: [],
    }),
    computed: {
        col3Props() {
            return {
                rowspan: this.sellerGroup.shippingGroups.reduce((sum, shippingGroup) => sum + shippingGroup.productGroups.length, 0),
            };
        },
    },
    methods: {
        sync() {
            this.selected = [...this.value];
        },
        emit(value) {
            this.$emit("input", value);
        },
        remove(item) {
            this.$emit("remove", item);
        },
        search() {
            this.$emit("search");
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>