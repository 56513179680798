var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('body-row', _vm._b({
    scopedSlots: _vm._u([{
      key: "col1",
      fn: function () {
        return [_c('item-product', _vm._g(_vm._b({
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        }, 'item-product', _vm.productGroup, false), {
          search: _vm.search,
          remove: _vm.remove,
          input: _vm.emit
        }))];
      },
      proxy: true
    }, {
      key: "col2",
      fn: function () {
        return [_c('item-price', _vm._b({
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        }, 'item-price', _vm.productGroup, false))];
      },
      proxy: true
    }, !!_vm.seller ? {
      key: "col3",
      fn: function () {
        var _vm$seller;
        return [_c('v-row', {
          staticClass: "justify-space-between align-center",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "d-md-none",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("판매자")]), _c('v-col', {
          attrs: {
            "cols": "auto",
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s((_vm$seller = _vm.seller) === null || _vm$seller === void 0 ? void 0 : _vm$seller.nickname))])])], 1)];
      },
      proxy: true
    } : null, !!_vm.shippingGroup ? {
      key: "col4",
      fn: function () {
        return [_c('item-shipping', _vm._b({}, 'item-shipping', {
          shippingGroup: _vm.shippingGroup
        }, false))];
      },
      proxy: true
    } : null], null, true)
  }, 'body-row', {
    col3Props: _vm.col3Props,
    col4Props: _vm.col4Props
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }