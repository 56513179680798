var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-displays', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return [0 < products.length ? _c('v-sheet', {
          staticClass: "d-none d-lg-block",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "tit-wrap tit-wrap--xs"
        }, [_c('div', {
          staticClass: "tit tit--xxs font-weight-medium"
        }, [_vm._v("다른 고객이 같이 구매한 상품")])]), _c('v-divider', {
          staticClass: "gutter",
          staticStyle: {
            "border-width": "0 0 2px"
          },
          attrs: {
            "dark": ""
          }
        }), _c('v-row', {
          staticClass: "row--xxs"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "product-items"
        }, [_vm._l(products, function (item, index) {
          return [_c('shop-product-item', {
            key: index,
            attrs: {
              "value": item,
              "sm": "6",
              "md": "3",
              "lg": "3"
            }
          })];
        })], 2)], 1), 1 < _vm.page ? _c('v-col', [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-small",
          attrs: {
            "disabled": _vm.page === 1,
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.go('prev');
            }
          }
        }, [_c('v-icon', {
          staticClass: "font-size-24 white--text"
        }, [_vm._v("mdi-chevron-left")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-small",
          attrs: {
            "disabled": _vm.page === _vm.pageCount,
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.go('next');
            }
          }
        }, [_c('v-icon', {
          staticClass: "font-size-24 white--text"
        }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()];
      }
    }])
  }, 'main-displays', {
    code: _vm.code,
    limit: _vm.limit
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }