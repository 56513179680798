import { render, staticRenderFns } from "./body-row.vue?vue&type=template&id=77efa736&scoped=true&"
import script from "./body-row.vue?vue&type=script&lang=js&"
export * from "./body-row.vue?vue&type=script&lang=js&"
import style0 from "./body-row.vue?vue&type=style&index=0&id=77efa736&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77efa736",
  null
  
)

export default component.exports