<template>
    <v-row class="row--xxs justify-space-between align-center text-center">
        <v-col cols="auto" md="12">
            <div class="txt txt--xs">총 배송비</div>
        </v-col>
        <v-col cols="auto" md="12">
            <strong class="font-size-16 break-all">
                {{ (servicePrice + deliveryPrice)?.format?.() }}<small class="font-size-14 font-weight-regular">원</small>
                <v-tooltip bottom v-if="shippingGroup.deliveryMessage">
                    <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>{{ shippingGroup.deliveryMessage }}</span>
                </v-tooltip>
            </strong>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        shippingGroup: { type: Object, default: () => ({}) },
    },
    computed: {
        carts() {
            return this.shippingGroup.productGroups.flatMap(({ carts }) => carts);
        },
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts);
        },
    },
};
</script>

<style>
</style>