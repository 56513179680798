<template>
    <v-sheet>
        <table-tool
            v-model="selected"
            v-bind="{
                carts,
                cartSellerGroups,
            }"
            v-on="{
                init,
                input: emit,
                emitLoading,
            }"
        />

        <v-simple-table class="v-data-table--default v-data-table--simple-mobile v-data-table--no-hover">
            <table-head />

            <template v-for="(sellerGroup, index) in cartSellerGroups">
                <table-body
                    v-model="selected"
                    v-bind="{
                        sellerGroup,
                        loading,
                    }"
                    v-on="{
                        input: emit,
                        remove,
                        search,
                    }"
                    :key="`body-${sellerGroup._store || index}`"
                />
                <table-foot
                    v-bind="{
                        sellerGroup,
                        loading,
                    }"
                    :key="`foot-${sellerGroup._store || index}`"
                />
            </template>
        </v-simple-table>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import TableBody from "./table/table-body.vue";
import TableFoot from "./table/table-foot.vue";
import TableHead from "./table/table-head.vue";
import TableTool from "./table/table-tool.vue";

export default {
    components: {
        TableBody,
        TableFoot,
        TableHead,
        TableTool,
    },
    props: {
        value: { type: Array, default: () => [] },

        carts: { type: Array, default: () => [] },
        cartSellerGroups: { type: Array, default: () => [] },
    },
    data: () => ({
        selected: [],

        loading: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
    },
    methods: {
        init() {
            this.$emit("init");
        },
        sync() {
            this.selected = [...this.value];
        },
        emit(value) {
            this.$emit("input", value);
        },
        syncLoading() {
            this.loading = this.$attrs.loading;
        },
        emitLoading(loading = this.loading) {
            this.$emit("emitLoading", loading);
        },

        async remove(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.carts.delete(item);
            } finally {
                this.loading = false;
                this.init();
            }
        },
        search() {
            this.$emit("search");
        },
    },
    watch: {
        value() {
            this.sync();
        },
        loading() {
            this.emitLoading();
        },
        "$attrs.loading"() {
            this.syncLoading();
        },
    },
    mounted() {
        this.sync();
        this.syncLoading();
    },
};
</script>

<style>
</style>