<template>
    <body-row v-bind="{ col3Props, col4Props }">
        <template #col1>
            <item-product
                v-model="selected"
                v-bind="productGroup"
                v-on="{
                    search,
                    remove,
                    input: emit,
                }"
            />
        </template>
        <template #col2>
            <item-price v-model="selected" v-bind="productGroup" />
        </template>
        <template #col3 v-if="!!seller">
            <v-row no-gutters class="justify-space-between align-center">
                <v-col cols="auto" class="d-md-none">판매자</v-col>
                <v-col cols="auto" md="12">
                    <div class="text-center">{{ seller?.nickname }}</div>
                </v-col>
            </v-row>
        </template>
        <template #col4 v-if="!!shippingGroup">
            <item-shipping v-bind="{ shippingGroup }" />
        </template>
    </body-row>
</template>

<script>
import BodyRow from "./body-row.vue";
import ItemPrice from "./item/item-price.vue";
import ItemProduct from "./item/item-product.vue";
import ItemShipping from "./item/item-shipping.vue";

export default {
    components: {
        BodyRow,
        ItemPrice,
        ItemProduct,
        ItemShipping,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected

        seller: { type: Object, default: null },
        shippingGroup: { type: Object },
        productGroup: { type: Object },

        col3Props: { type: Object, default: () => ({}) },
        col4Props: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        selected: [],
    }),
    methods: {
        sync() {
            this.selected = [...this.value];
        },
        emit(value) {
            this.$emit("input", value);
        },
        remove(item) {
            this.$emit("remove", item);
        },
        search() {
            this.$emit("search");
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>