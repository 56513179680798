var render = function render(){
  var _ref, _ref$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs justify-space-between align-center text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("총 배송비")])]), _c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 break-all"
  }, [_vm._v(" " + _vm._s((_ref = _vm.servicePrice + _vm.deliveryPrice) === null || _ref === void 0 ? void 0 : (_ref$format = _ref.format) === null || _ref$format === void 0 ? void 0 : _ref$format.call(_ref))), _c('small', {
    staticClass: "font-size-14 font-weight-regular"
  }, [_vm._v("원")]), _vm.shippingGroup.deliveryMessage ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-4px cursor-pointer",
          attrs: {
            "small": "",
            "color": "grey lighten-3"
          }
        }, 'v-icon', attrs, false), on), [_vm._v("mdi-help-circle-outline")])];
      }
    }], null, false, 1857201869)
  }, [_c('span', [_vm._v(_vm._s(_vm.shippingGroup.deliveryMessage))])]) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }