var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_vm._l(_vm.sellerGroup.shippingGroups, function (shippingGroup, i) {
    return [_vm._l(shippingGroup.productGroups, function (productGroup, j) {
      return [_c('body-item', _vm._g(_vm._b({
        key: productGroup === null || productGroup === void 0 ? void 0 : productGroup._product,
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v;
          },
          expression: "selected"
        }
      }, 'body-item', {
        seller: i == 0 && j == 0 ? _vm.sellerGroup.seller : null,
        shippingGroup: j == 0 ? shippingGroup : null,
        productGroup,
        col3Props: j == 0 ? _vm.col3Props : null,
        col4Props: {
          rowspan: i == 0 ? shippingGroup.productGroups.length : null
        }
      }, false), {
        search: _vm.search,
        remove: _vm.remove,
        input: _vm.emit
      }))];
    })];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }