<template>
    <tbody>
        <tr tile v-bind="{ loading }">
            <td colspan="100%" class="text-center py-9">
                <span> {{ productPrice.format() }}원 </span>
                <span> + </span>
                <span> 
                    {{ !isShippingPayOnDelivery ? (shippingPrice > 0 ? `배송비 ${shippingPrice.format()}원` : "배송비 무료") : "배송비 착불" }} 
                </span>
                <span> = </span>
                <span> {{ totalPrice.format() }}원 </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { SHIPPING_OPTION_CHARGE_TYPES } from '@/assets/variables';
export default {
    props: {
        sellerGroup: { type: Object, default: () => ({}) },
        loading: { type: Boolean, default: false },
    },
    computed: {
        carts() {
            return this.sellerGroup.shippingGroups.flatMap((shippingGroup) => shippingGroup.productGroups.flatMap(({ carts }) => carts));
        },
        productPrice() {
            return this.carts.reduce((total, item) => total + item.salePrice * item.amount, 0);
        },
        shippingPrice() {
            return this.sellerGroup.shippingGroups.reduce((totalPrice, shippingGroup) => {
                const carts = shippingGroup.productGroups.flatMap(({ carts }) => carts);
                const servicePrice = this.$getServicePrice(carts);
                const deliveryPrice = this.$getDeliveryPrice(carts);
                return totalPrice + servicePrice + deliveryPrice;
            }, 0);
        },
        totalPrice() {
            return this.productPrice + this.shippingPrice;
        },

        isShippingPayOnDelivery() {
            return this.sellerGroup.shippingGroups.every(shippingGroup => 
                shippingGroup.productGroups.every(productGroup => 
                    productGroup.shippingChargeType === SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value
            ));
        },
    },
};
</script>

<style>
</style>