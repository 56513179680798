<template>
    <cart-page tit="장바구니">
        <cart-view />
        <cart-slide />
    </cart-page>
</template>

<script>
import CartPage from "@/components/client/templates/cart-page.vue";
import CartView from "@/components/client/shop/cart/cart-view.vue";
import CartSlide from "@/components/client/shop/cart/cart-slide.vue";

export default {
    components: {
        CartPage,
        CartView,
        CartSlide,
    },
};
</script>
