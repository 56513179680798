<template>
    <v-card tile outlined>
        <div class="pa-18px px-md-40px py-md-32px">
            <v-row align="center">
                <v-col cols="12">
                    <div class="tit-wrap">
                        <div class="tit font-weight-light">주문정보</div>
                    </div>
                    <v-row align="center" justify="center" class="row--sm txt font-weight-light">
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span>총 주문금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span> {{ productPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt font-weight-light">+ 총 배송비</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span>{{ (servicePrice + deliveryPrice).format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt font-weight-light">- 총 할인금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span>{{ discountPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-divider />
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" class="row--xxs">
                                <v-col cols="12" class="text-end">
                                    <span>총 {{ selected.length }} 개</span>
                                </v-col>
                                <v-col cols="12" class="text-end">
                                    <div class="tit font-weight-light">
                                        <span class="red--text">{{ totalPrice.format() }}</span
                                        >원
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- <v-col cols="12">
                    <v-divider />
                </v-col> -->
                <!-- <v-col cols="12">
                    <v-row align="center" justify="end" class="row--sm">
                        <v-col cols="12" sm="auto">
                            <v-btn v-bind="{ ...btn_secondary }" x-large class="w-100 min-w-sm-200px" @click="$emit('order', selected)">선택 주문하기</v-btn>
                        </v-col>
                    </v-row>
                </v-col> -->
            </v-row>
        </div>
        <v-sheet class="grey lighten-5 pa-18px px-md-40px py-md-24px">
            <div class="tit-wrap tit-wrap--xs">
                <div class="txt txt--dark font-weight-medium">이용안내</div>
            </div>
            <div class="txt txt--sm">
                <p>상품은 30일간 보관됩니다. 장기간 보관을 원하실 경우 [관심상품]을 이용해 주세요.</p>
                <p>주문 시 편의점 픽업을 지정하신 경우, 편의점 픽업가능 상품끼리만 주문 가능하며 한 곳의 편의점으로만 배송이 가능합니다.</p>
                <p>실 결제금액은 주문결제에서 프로모션/쿠폰 적용에 따라 달라질 수 있습니다</p>
            </div>
        </v-sheet>
        <v-btn tile block color="v-size--xx-large primary" v-bind="{ loading }" @click="$emit('order', selected)">구매하기</v-btn>
    </v-card>
</template>

<script>
import { btn_secondary } from "@/assets/variables";

export default {
    props: {
        selected: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        btn_secondary,
    }),
    computed: {
        productPrice() {
            return this.$getProductPrice(this.selected);
        },
        servicePrice() {
            return this.$getServicePrice(this.selected);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.selected);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.selected);
        },
        totalPrice() {
            return this.productPrice + this.servicePrice + this.deliveryPrice - this.discountPrice;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
